import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const SideviewMirrors = () => {
  return (
    <Layout>
      <Head title="Sideview Mirrors" />
      <h1>Sideview Mirrors</h1>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777875/sideview-mirrors/IMG_4312.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777875/sideview-mirrors/IMG_4383.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777872/sideview-mirrors/IMG_3037.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777871/sideview-mirrors/IMG_2617.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777863/sideview-mirrors/IMG_2783.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777859/sideview-mirrors/IMG_2484.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777853/sideview-mirrors/IMG_2502.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777851/sideview-mirrors/IMG_0671_copy.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777849/sideview-mirrors/IMG_2675.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777847/sideview-mirrors/IMG_2508.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777841/sideview-mirrors/IMG_1280.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777840/sideview-mirrors/IMG_1271.jpg"
        alt="sideview mirror series"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1657777838/sideview-mirrors/IMG_2088.jpg"
        alt="sideview mirror series"
      />

      <Link to="/photography">
        <h3>Photography</h3>
      </Link>
      <Link to="/media">
        <h3>Media</h3>
      </Link>
      <Link to="/">
        <h3>Home</h3>
      </Link>
    </Layout>
  );
};

export default SideviewMirrors;
